<template>
   <div>
      <Menubar :model="items" style="background-color: #d1e4dd;">
         <template #start>
            <span>
               <img alt="logo" src="../../assets/Favicon560_256_1.png" height="60" class="mr-2 md-2" />
            </span>
            &nbsp; &nbsp;
         </template>
         <template #end>
            <span>
               <i v-if="isLoggedGlob" class="pi pi-circle-fill"
                  style="vertical-align: 0.5em; color: green; font-size: 20px;"></i>
               <i v-if="!isLoggedGlob" class="pi pi-circle-off"
                  style="vertical-align: 0.5em; color: green; font-size: 20px;"></i>
               &nbsp;
            </span>
            <span>
               <Button id="Vasya" @click='showCart' icon="pi pi-shopping-cart" class="p-button-text p-button-outlined"
                  label="FIN" />
               &nbsp; &nbsp;
            </span>
         </template>
      </Menubar>
   </div>
</template>

<script>
// import ModalAuth from '../../pages/auth/ModalAuth.vue';
import { ref, inject } from 'vue';

export default {
   setup() {
      const isLoggedGlob = inject("isLoggedGlob", ref(false));
      return {
         isLoggedGlob,
      };
   },
   data() {
      return {
         //count: 0,
         //isModalVisible: false,
         //label1: 'LoginRegister',
         //text: null,
         items: [
            {
               label: 'Home',
               icon: 'pi pi-fw pi-home',
               to: '/catalog'
            },
            {
               label: 'Info',
               icon: 'pi pi-fw pi-info-circle',
               items: [
                  {
                     label: 'About',
                     icon: 'pi pi-fw pi-id-card',
                     to: '/about'
                  },
                  {
                     label: 'Policies',
                     icon: 'pi pi-fw pi-book',
                     to: '/policies'
                  },
                  {
                     label: 'Contact',
                     icon: 'pi pi-fw pi-send',
                     to: '/contacts'
                  },

               ]
            },
            {
               label: 'Search',
               icon: 'pi pi-fw pi-search',
            },
            {
               //                   label: ()=> { return this.label1; },
               label: () => 'Login/Quit',
               icon: 'pi pi-fw pi-user',
               to: 'AuthPage',
               //                   command: ()=>{
               //                     this.isModalVisible = true;
               //                   }, 
            },
            //{
            //   label: 'Profile/Logout',
            //   icon: 'pi pi-fw pi-user',
            //   visible: () => { return false; },
            //},
         ]
      }
   },
   methods: {
      //closeModal() {
      //   this.count++;
      //   this.isModalVisible = false;
      //},
      showCart() {
         this.$router.push("/order");
      },
   },
   //isLabelVisible() {
   //   return false;
   //},
   //beforeCreate() {
   //   console.log('beforeCreate ' + this.count);
   //},
   //created() {
   //   this.count++;
   //   console.log('created ' + this.count);
   //},
   //mounted() {
   //   this.count++;
   //   console.log('mounted ' + this.count);
   //},
}
</script>

<style>
.pi-bars {
   font-size: 2rem !important;
}

.pi-shopping-cart {
   font-size: 1.5rem !important;
}

#Vasya {
   width: 6rem;
}
</style>