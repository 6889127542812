<template>
<div class="infotext">
<h1>Wininfin Terms of Use and Policies</h1>  
The use of this website WININFIN.COM and all included materials is subject to the following 
regulations: 
<br><h3>Wininfin Terms of Use</h3>
version No.3 of 09 May 2023
<br>Copyright © 2016-2023 Anton Nefedov
<br>Published at: wininfin.com
<br><br>This website wininfin.com (hereinafter referred to as the “Wininfin”, the “Wininfin Website” 
or the “Website”) is owned and managed by Anton Nefedov, an individual 
(hereinafter referred to as "The Owner").
<br><br>These Wininfin Terms of Use (further equivalently referred to as “Terms of Use” or just “Terms”)
constitute a legal contract between you and The Owner which governs your use of the website.
<br><br>It is acknowledged that you accept the Terms of Use if you either (a) start to extract 
or use any piece of electronic information available at the website in whatever form 
(text, images, audio and video records, files, data from databases, etc., further referred 
to as the “Content”), and by whatever medium (web-browsers and other applications, programs, 
electronic devices or using other methods), or (b) if you transfer information addressed to the 
website or addressed to The Owner in respect of the website, including but not limited to input 
of personal data during registration at the website, submission of questions, proposals or comments
 via contact forms, e-mails, regular mails, etc or (c) if you make any payments for the content 
 offered at the website.
<br><br>The Wininfin website contains chargeable and freeloading content. If any content is not directly
 identified as chargeable by a method conventional in usual business-practice, then such a content
  is treated as freeloading and does not require any payments from you.
<br><br>In some cases you may receive access to the content only after registration on the Wininfin
 website, but in some other cases it is possible without such a registration. Access to chargeable 
 content may be granted only upon receipt of your payment.
<br><br>For the purpose of registration at the website you will need to download to the website 
limited scope of personal data necessary for your identification and/or for possibility to contact 
you in the case if it will be required. Beyond that you may specify optional personal information 
at your own desire in the event of postings of questions, proposals or comments to the website and 
in other circumstances. Rules of processing of personal data received from individuals are established
in the “Privacy policy” published at the website.
<br><br>All programs, video files and any other files which you may download from the website or 
from a server of the website are protected by the copyright law. Terms of use of these files are 
established in licenses published on the Wininfin website. The Owner may at any time change type 
of the license for each downloaded file or change license terms in any license. New license terms
 are effective since the date of publication at the website and are applicable to files downloaded
 after the publication date.
<br><br>The Wininfin website provides the chargeable and free content “as is” and is not responsible 
for any sort of unsatisfactory performance, damaging events for you and to third parties resulting 
from use of the content.
<br><br>You agree to use Wininfin only for purposes that are jointly permitted by these Terms, 
by law applicable in jurisdictions you have relevance to (based on your citizenship, 
place of work, etc) and by generally accepted rules of business, normal public and social behaviour.
<br><br>The Wininfin website does not moderate any information you left at the website via publication 
of questions, proposals or comments, uploading of files or using of any other methods. 
You agree that you are solely responsible for any information that you create, transmit or 
display while using the Wininfin website, and for the consequences of your actions by doing so, 
including any loss or damage that The Owner may suffer.
<br><br>The Owner may change at any pointing time fully or partly any terms from the present Terms 
of Use. New Terms are effective starting from the date of publication of new version of the Terms 
of Use at the website.
<br><br>If you do not agree with the present Terms of Use, then you are not allowed to use the Wininfin 
website as a whole and you are not allowed to use any content and any piece of content from the website.
<br>
<br><h3>Wininfin Privacy Policy</h3>
version No.3 of 09 May 2023
<br>Copyright © 2016-2023 Anton Nefedov
<br>Published at: wininfin.com
<br><br>This website wininfin.com (hereinafter referred to as the “Wininfin”, the “Wininfin Website” 
or the “Website”) is owned and managed by Anton Nefedov, an individual 
(hereinafter referred to as "The Owner").
<br><br>This Wininfin Privacy Policy (further also equivalently referred to as the “Privacy Policy”) 
describes how The Owner processes and protects private information about you and about other users 
of the Wininfin website.
<br><br>The Owner processes personal information collected from you and from other users of 
the Wininfin website in order to execute contractual obligations of The Owner imposed by the 
Wininfin Terms of Use published at the website, in relation to you and other users treated as 
subjects of personal data and contractual parties of the Wininfin Terms of Use.
<br><br>In particular, The Owner uses your personal data for your registration at the website, 
handling of your questions, proposals or comments that you submit via electronic forms available 
at the website or using other methods, place your orders at the website,  receive and identify
your payments for chargeable content, submit cash receipts for your payments, ensure downloading 
of free and chargeable files, videos and other content, exchange of business correspondence 
related to your use of  the Wininfin services managed by The Owner.
<br><br>Normally Wininfin website may collect only your name, e-mail address and password during the 
registration process. In addition to that in some specific cases Wininfin may collect your 
nickname, second name and telephone number. If you purchase a product or services from the 
Wininfin website, then Wininfin may request and collect your credit card information. 
Then your credit card information will be used by a third party service provider to process 
your payments.
<br><br>Wininfin website does not know your real password because keeps it in the form of encoded hash. 
<br><br>If you contact The Owner as a representative of a legal entity then The Owner may request and 
collect information about your full name, name of your company, your position and address of your 
place of work.
<br><br>If you will leave comments at the website, then Wininfin may process your IP-address and 
data of your user-agent browser.
<br><br>If you will leave accidentally your additional personal information (not listed above) 
during the registration process on the Wininfin website either in private correspondence with 
the administration of the website (not available publicly) or in your comments posted at the 
website (available publicly), then such excessive personal data will be removed from the website 
and from databases of the website straight after detection.
<br><br>Despite of all the above said, Wininfin website has no responsibility for public release 
of your excessive personal data not requested by Wininfin that you published unwittingly or 
wilfully in the comments left at the website.
<br><br>Wininfin will not transfer your personal data to third parties other than providers of 
credit card payment services.
<br><br>From time to time The Owner may correct some provisions of the Privacy Policy or add 
new provisions. Updated Privacy Policy will be effective since the date of its publication 
at the website.
</div>
</template>

<style scoped>
.infotext {
  text-align: left;
  padding: 5px;
/*  max-width: 800px; */
}
</style>