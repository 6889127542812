<template>
<!--  <div>
    <h1 style="color:blueviolet; font-size:300%;">Warning - Warning - Warning !!!</h1>
    <h1 style="color:red; font-size:300%;">This site is currently</h1>
    <h1 style="color:red; font-size:300%;">under reconstruction !!!</h1>
  </div> -->
  <div v-for="product in products" :key="product.row_id">
    <div v-if="product.product_idid_prod === 1">
      <h3 id="VideoFlag">Free video</h3>
      <div class="video-container">
        <iframe width="560" height="315" :src="product.source" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <p class="n1" style="font-weight: bold; font-size: 100%;">{{ product.product_name_prod }}</p>
    </div>
    <div v-if="product.type_prod == 'text'">
      <p class="n1"> {{ product.product_name_prod }}</p>
    </div>
    <div v-if="product.type_prod == 'cart'">
      <p class="n1 anb1"> {{ product.product_name_prod }} price
        <span style="color:red; font-weight: bold; font-size: 20px">$ {{ product.product_price_prod }}</span>
        <button v-if="!product.status_journ && !isLoggedIn" @click="gotoLogin" class="butcart cartgrey">
          <i class="pi pi-shopping-cart" style="color: white;"></i>
        </button>
        <button v-if="!product.status_journ && isLoggedIn" @click="putinCart(product.product_id_prod)"
          class="butcart cartgreen">
          <i class="pi pi-shopping-cart" style="color: white;"></i>
        </button>
        <button v-if="product.status_journ == 1" class="butcart cartyellow" disabled>
          <i class="pi pi-shopping-cart" style="color: white;"></i>
        </button>
        <button v-if="product.status_journ == 2" class="butcart cartcyan" disabled>
          <i class="pi pi-shopping-cart" style="color: white;"></i>
        </button>
        <button v-if="product.status_journ == 3" class="butcart cartwhite" disabled>
          <i class="pi pi-box" style="font-size: 2rem; color: red"></i>
        </button>
        <button v-if="product.status_journ == 4" class="butcart cartcyan" disabled>
          <i class="pi pi-shopping-cart" style="color: white;"></i>
        </button>
        <span v-if="!product.status_journ && !isLoggedIn"> - click to add (Login required)</span>
        <span v-if="!product.status_journ && isLoggedIn"> - click to add</span>
        <span v-if="product.status_journ == 1"> - already added to cart</span>
        <span v-if="product.status_journ == 2"> - has been ordered (wait for your payment)</span>
        <span v-if="product.status_journ == 3"> - available !</span>
        <span v-if="product.status_journ == 4"> - has been ordered (and partly paid)</span>
        <br><span>Included materials:</span>
      </p>
    </div>
    <div v-if="product.type_prod == 'word'
      || product.type_prod == 'excel'
      || product.type_prod == 'filezip'
      || product.type_prod == 'filepdf'
      || product.type_prod == 'file'">
      <p class="n1">{{ product.product_name_prod }}
        <button v-if="product.status_journ != 3" class="loadfile butgrey" style="color:white;">load</button>
        <button v-if="product.status_journ == 3" class="loadfile butlightgreen butactive"
          @click="loadFile(product.row_id)">load</button>
      </p>
    </div>
  </div>
  <div>
    <Paginator v-model:first="rowFirst" :rows="rowsOnPage" :totalRecords="rowsTotal" class="pgn1"></Paginator>
  </div>
  <br><br><br><br>
  <Toast />
</template>

<script>
import axios from 'axios';
import { useToast } from '../../../node_modules/primevue/usetoast';
import { ref, inject } from 'vue';
export default {
  setup() {
    const isLoggedGlob = inject("isLoggedGlob", ref(false));
    return {
      isLoggedGlob,
      ilt() {
        isLoggedGlob.value = true;
      },
      ilf() {
        isLoggedGlob.value = false;
      },
    };
  },
  data() {
    return {
      aa: "",
      bb: "",
      cc: 0,
      dd: "", ee: "", ff: "", gg: "", hh: "", pp: "", qq: "",
      toast: useToast(),
      rowFirst: 0,
      isLoggedIn: false,
      rowsOnPage: 5,
      rowsTotal: 5,
      products: [],
    };
  },
  methods: {
    async makeRequestWith_DoubleToken() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/ping`;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.get(path, options1);
        this.aa = response.data;
        const vasya2 = response.headers.get(["Var-Antolog"]);
        this.bb = vasya2;
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "warn", summary: "Warning", detail: "No activity for long time. Please re-login if needed.", life: 3000 });
      }
    },
    timeOne() {
      this.cc = Date.now();
      const vasya = this.cc + 1.0e13;
      this.dd = new Date(vasya).toString();
      const vova = String(vasya) + "Vasya";
      this.ee = vova;
      this.ff = vova.substring(0, 14);
      this.gg = Number(this.ff) - 1.0e13;
      this.hh = vova.substring(14);
    },
    timeDiff() {
      this.pp = Date.now() - this.gg;
    },
    checkCookie() {
      const tkn = "zetartant";
      const matches = document.cookie.match(new RegExp(
        // DON'T REMOVE BELOW COMMENT !  
        //eslint-disable-next-line
        "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      if (matches) {
        this.qq = "cookie exists";
      } else {
        this.qq = "cookie does not exist";
      }
    },
    async checkLogin() {
      const tkn = "zetartant";
      const matches = document.cookie.match(new RegExp(
        // DON'T REMOVE BELOW COMMENT !  
        //eslint-disable-next-line
        "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      if (matches) {
        const deltat = Date.now() + 1.0e13 - Number(matches[1].substring(0, 14));
        if (deltat > 30 * 60 * 1000) {
          await this.CookieRemove();
          if (this.isLoggedIn) {
            this.toast.add({ severity: "warn", summary: "Warning", detail: "No activity for long time. Please re-login if needed.", life: 3000 });
          }
          if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
          this.isLoggedIn = false;
        } else if (deltat > 20 * 60 * 1000) {
          await this.makeRequestWith_DoubleToken();
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true;
        } else {
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true
        }
      } else {
        await this.CookieRemove();
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      }
    },
    async CookieRemove() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/logout_with_cookies`;
        //const response = await axios.post(path);
        await axios.post(path);
        document.cookie = "zetartant=;max-age=0";
        //console.log(response);
        if (this.isLoggedIn) {
          this.toast.add({ severity: "info", summary: "Info.", detail: "You were Logged-out", life: 3000 });
        }
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getProducts() {
      await this.checkLogin();
      if (this.isLoggedIn == true) {
        try {
          const path = `${process.env.VUE_APP_ROOT_API}/get_products`;
          axios.defaults.withCredentials = true;
          const data1 = {
            'userflag': "1", 'rowfirst': this.rowFirst,
          };
          const tkn = "zetartant";
          const matches = document.cookie.match(new RegExp(
            // DON'T REMOVE BELOW COMMENT !  
            //eslint-disable-next-line
            "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          const options1 = {
            headers: {
              'X-CSRF-TOKEN': matches[1].substring(14),
            }
          };
          const response = await axios.post(path, data1, options1);
          this.products = response.data;
          const vasya2 = response.headers.get(["Var-Antolog"]);
          if (vasya2) {
            const dtt = Date.now() + 1.0e13;
            const vasya3 = "zetartant=" + String(dtt) + vasya2;
            document.cookie = vasya3;
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const path = `${process.env.VUE_APP_ROOT_API}/get_products`;
          axios.defaults.withCredentials = true;
          const data1 = {
            'userflag': "0", 'rowfirst': this.rowFirst,
          };
          const response = await axios.post(path, data1);
          this.products = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async putinCart(p_id) {
      console.log("p_id= ", p_id);
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/putproduct_incart`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'prodid': p_id,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
      await this.getProducts();
    },
    gotoLogin() {
      this.$router.push("/authpage");
    },
    async loadFile(x) {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/get_payload_file/${x}`;
        axios.defaults.withCredentials = true;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          },
          responseType: 'blob',
        };
        const response = await axios.get(path, options1);
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = "";
        const filename0 = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        filename = filename0[1].replace(/['"]/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const aEl = document.createElement("a");
        Object.assign(aEl, { href: url, download: filename });
        document.body.appendChild(aEl);
        aEl.click();
        aEl.remove();
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async checkItemsNumber() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/check_itemsnumber`;
        axios.defaults.withCredentials = true;
        const response = await axios.get(path);
        this.rowsTotal = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRows() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/get_rows`;
        axios.defaults.withCredentials = true;
        const response = await axios.get(path);
        const rdata = response.data;
        this.rowsOnPage = rdata.rowsonpage;
        this.rowsTotal = rdata.rowstotal;
      } catch (error) {
        console.error(error);
      }
    },    
    async ini() {
      await this.getRows();
      await this.checkLogin();
      await this.getProducts(); //double checkLogin
    },
  },
  watch: {
    async rowFirst() {
      await this.getProducts();
    },
    isLoggedIn(newflag) {
      if (newflag) { this.ilt(); }
      else { this.ilf(); }
    },
  },
  mounted() {
    this.ini();
  },
}
</script>


<style scoped>
#VideoFlag {
  margin-bottom: 0px;
  margin-left: 12.5%;
  color: white;
  background-color: greenyellow;
  text-align: center;
  width: 150px;
}

.video-container {
  position: relative;
  padding-bottom: calc(56.25% * 0.75);
  /*16:9 */
  width: 75%;
  height: 0;
  margin-left: 12.5%;
}

.video-container iframe,
video-container object,
video container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.n1 {
  width: 75%;
  margin-left: 12.5%;
  text-align: left;
}

.pgn1 {
  background-color: #d1e4dd;
}

.anb1 {
  display: inline-block;
  vertical-align: middle;
}

.butcart {
  width: 70px;
  border: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-left: 20px;
  margin-right: 10px;
}

.cartgrey {
  background-color: darkgrey;
}

.cartgreen {
  background-color: darkgreen;
}

.cartyellow {
  background-color: yellow;
}

.cartcyan {
  background-color: cyan;
}

.cartwhite {
  background-color: white;
}

.cartgrey:hover,
.cartgreen:hover {
  background-color: darkslateblue;
  cursor: pointer;
}

.loadfile {
  border: none;
  border-radius: 5px;
  height: 20px;
  width: 50px;
  margin-left: 20px;
  margin-right: 10px;
}

.butlightgreen {
  background-color: lightgreen;
}

.butgrey {
  background-color: darkgrey;
}

.butactive:hover {
  background-color: darkslateblue;
  color: white;
  cursor: pointer;
}
</style>
