<template>
  <basic-menu></basic-menu>
  <router-view></router-view>
</template>

<script>
import BasicMenu from './components/menu/BasicMenu.vue'
import { ref, provide } from 'vue';

export default {
  components: {
    BasicMenu
  },
  setup() {
    const isLoggedGlob = ref(false);
    provide("isLoggedGlob", isLoggedGlob);
    return {
      isLoggedGlob,
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: #d1e4dd;
}
</style>
