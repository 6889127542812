import { createRouter, createWebHistory } from 'vue-router';

import WorkCatalog from './pages/work/WorkCatalog.vue';
import WorkInvoice from './pages/work/WorkInvoice.vue';
import WorkOrder from './pages/work/WorkOrder.vue';
import InfoAbout from './pages/work/InfoAbout.vue';
import InfoPolicies from './pages/work/InfoPolicies.vue';
import InfoContacts from './pages/work/InfoContacts.vue';
import AuthPage from './pages/auth/AuthPage.vue';
import NotFound from './pages/NotFound.vue';
//import ModalAuth from './pages/auth/ModalAuth.vue';
//import UserAuth from './pages/auth/UserAuth.vue';
//import store from './store/index.js';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/catalog' },
    { path: '/catalog', component: WorkCatalog },
    { path: '/invoice', name: 'invoice', component: WorkInvoice },
    { path: '/order', component: WorkOrder },
    { path: '/about', component: InfoAbout },
    { path: '/policies', component: InfoPolicies },
    { path: '/contacts', component: InfoContacts },
    { path: '/authpage', component: AuthPage },
    //    { path: '/auth', component: ModalAuth },
    /*    {
          path: '/coaches/:id',
          component: CoachDetail,
          props: true,
          children: [
            { path: 'contact', component: ContactCoach } // /coaches/c1/contact
          ]
        },
        { path: '/register', component: CoachRegistation, meta: { requiresAuth: true } },
        { path: '/requests', component: RequestsReceived, meta: { requiresAuth: true } },
        { path: '/auth', component: UserAuth, meta: { requiresUnauth: true } },*/
    { path: '/:notFound(.*)', component: NotFound }
  ]
});

/*router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/auth');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/coaches');
  } else {
    next();
  }
}); */

export default router;