<template>
  <!--  <div>
    <h1 style="color:blueviolet; font-size:300%;">Warning - Warning - Warning !!!</h1>
    <h1 style="color:red; font-size:300%;">This site is currently</h1>
    <h1 style="color:red; font-size:300%;">under reconstruction !!!</h1>
  </div> -->
  <h3>Your cart /orders /payments</h3>
  Table 1. Purchased products
  <br>(paid orders)
  <br>
  <div v-for="ord in ord_sum_paid" :key="ord.order">
    <br>Order No: &nbsp; {{ ord.order }}
    <table class="tc" style="width:100%; max-width: 600px;">
      <thead>
        <tr>
          <th style="width:12%">Prod No</th>
          <th style="width:76%">Prod Name</th>
          <th>Price, $</th>
        </tr>
      </thead>
      <tbody v-if="!flagStatus3">
        <tr>
          <td></td>
          <td>You do not have ordered paid products</td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="product in userjournal" :key="product.rec_id">
          <tr v-if="product.status_journ == 3 && product.order_no_journ == ord.order">
            <td>{{ product.product_id_journ }}</td>
            <td>{{ product.product_name_journ }}</td>
            <td>{{ product.product_price_journ }}</td>
          </tr>
        </template>
        <tr>
          <td></td>
          <td style="text-align: right;">Total: &nbsp;</td>
          <td style="font-weight: bold;">{{ ord.sum }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <br><br>
  Table 2. Outstanding orders
  <br>(waiting for payments)
  <br>
  <div v-for="ord in ord_sum_notpaid" :key="ord.order">
    <br>Order No: &nbsp; {{ ord.order }}
    <table class="tc" style="width:100%; max-width: 600px;">
      <thead>
        <tr>
          <th style="width:12%">Prod No</th>
          <th style="width:76%">Prod Name</th>
          <th>Price, $</th>
        </tr>
      </thead>
      <tbody v-if="!flagStatus2">
        <tr>
          <td></td>
          <td>You do not have ordered products</td>
          <td></td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="product in userjournal" :key="product.rec_id">
          <tr v-if="product.status_journ == 2 && product.order_no_journ == ord.order">
            <td>{{ product.product_id_journ }}</td>
            <td>{{ product.product_name_journ }}</td>
            <td>{{ product.product_price_journ }}</td>
          </tr>
        </template>
        <tr>
          <td></td>
          <td style="text-align: right;">Total: &nbsp;</td>
          <td style="font-weight: bold;">{{ ord.sum }}</td>
        </tr>
      </tbody>
    </table>
    <div class="tc" style="width:100%; max-width:600px">
      <div class="inl-2" style="width: 40%;  text-align: left;">
        <a @click="cancelOrder(ord.order)" class="rem-class">cancel this Order</a>
      </div>
      <div class="inl-2" style="position: relative; top: 5px; text-align: right;">
        &nbsp; &nbsp; <span class="inlblk">Get Invoice:</span> &nbsp;
        <button class="cbtn btn-eth inlblk" @click="getInv('ETH', ord.order)">
          &nbsp; &nbsp; Ethereum
        </button> &nbsp; &nbsp;
        <button class="cbtn btn-btc" type="button" @click="getInv('BTC', ord.order)">
          &nbsp; &nbsp; Bitcoin
        </button>
      </div>
    </div>
  </div>
  <br><br>
  Table 3. Partly paid items
  <br><br>
  <table class="tc" style="width:100%; max-width: 600px;">
    <thead>
      <tr>
        <th style="width:12%">Prod No</th>
        <th style="width:52%">Prod Name</th>
        <th style="width:12%">Price, $</th>
        <th style="width:12%">Partly paid, $</th>
        <th>Allocate</th>
      </tr>
    </thead>
    <tbody v-if="!flagStatus5">
      <tr>
        <td></td>
        <td>You do not have partly paid products</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody v-else>
      <template v-for="product in userjournal" :key="product.rec_id">
        <tr v-if="product.status_journ == 4">
          <td>{{ product.product_id_journ }}</td>
          <td>{{ product.product_name_journ }}</td>
          <td>{{ product.product_price_journ }}</td>
          <td>{{ product.prod_amntpaid_journ }}</td>
          <td v-if="usepay_rec_id != 0">
            <a @click="allocatePrepayment(product.product_id_journ)" class="rem-class">allocate</a>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <br><br>
  Table 4. Cart
  <br><br>
  <table class="tc" style="width:100%; max-width: 600px;">
    <thead>
      <tr>
        <th style="width:12%">Prod No</th>
        <th style="width:52%">Prod Name</th>
        <th style="width:12%">Price, $</th>
        <th style="width:12%">Remove</th>
        <th>Allocate</th>
      </tr>
    </thead>
    <tbody v-if="!flagStatus1">
      <tr>
        <td></td>
        <td>You do not have products in cart</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody v-else>
      <template v-for="product in userjournal" :key="product.rec_id">
        <tr v-if="product.status_journ == 1">
          <td>{{ product.product_id_journ }}</td>
          <td>{{ product.product_name_journ }}</td>
          <td>{{ product.product_price_journ }}</td>
          <td>
            <a @click="removeFromCart(product.product_id_journ)" class="rem-class">remove</a>
          </td>
          <td v-if="usepay_rec_id != 0">
            <a @click="allocatePrepayment(product.product_id_journ)" class="rem-class">allocate</a>
          </td>
        </tr>
      </template>
      <tr>
        <td></td>
        <td style="text-align: right;">Total: &nbsp;</td>
        <td style="font-weight: bold;">{{ cart_sum }}</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <a v-if="flagStatus1" @click="placeOrder()" class="rem-class">Place Order with Cart items</a>
  <br><br>
  Table 5. Payments
  <br><br>
  <table class="tc" style="width:100%; max-width: 400px;">
    <thead>
      <tr>
        <th>Order No</th>
        <th>Amount, $</th>
        <th>Spent, $</th>
        <th>Available, $</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody v-if="!flagStatus4">
      <tr>
        <td></td>
        <td></td>
        <td>You have not made any payments</td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody v-else>
      <template v-for="product in userjournal" :key="product.rec_id">
        <tr v-if="product.status_journ == 21 || product.status_journ == 22">
          <td v-if="product.status_journ == 21">{{ product.order_no_journ }}</td>
          <td v-else>Advance</td>
          <td>{{ product.product_price_journ }}</td>
          <td v-if="product.status_journ == 21">{{ product.product_price_journ }}</td>
          <td v-else-if="product.prod_amntpaid_journ > 0">{{ product.prod_amntpaid_journ }}</td>
          <td v-else>-</td>
          <td v-if="product.status_journ == 21">-</td>
          <td v-else-if="product.product_price_journ == product.prod_amntpaid_journ">-</td>
          <td v-else>{{ product.product_price_journ - product.prod_amntpaid_journ }}</td>
          <td v-if="product.status_journ == 21">-</td>
          <td v-else-if="product.product_price_journ == product.prod_amntpaid_journ">-</td>
          <td v-else>
            <a v-if="usepay_rec_id != product.rec_id" @click="choosePrepayment(product.rec_id)"
              class="use-class standby">off</a>
            <a v-else @click="choosePrepayment(product.rec_id)" class="use-class in-use">on</a>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <br><br><br><br>
</template>

<script>
import axios from 'axios';
import { ref, inject } from 'vue';

export default {
  setup() {
    const isLoggedGlob = inject("isLoggedGlob", ref(false));
    return {
      isLoggedGlob,
      ilt() {
        isLoggedGlob.value = true;
      },
      ilf() {
        isLoggedGlob.value = false;
      },
    };
  },
  data() {
    return {
      isLoggedIn: false,
      userjournal: [],
      flagStatus1: false,
      flagStatus2: false,
      flagStatus3: false,
      flagStatus4: false,
      flagStatus5: false,
      ord_paid: [],
      ord_sum_paid: [],
      ord_notpaid: [],
      ord_sum_notpaid: [],
      cart_sum: 0,
      usepay_rec_id: 0,
    };
  },
  methods: {
    async makeRequestWith_DoubleToken() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/ping`;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.get(path, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "warn", summary: "Warning", detail: "No activity for long time. Please re-login if needed.", life: 3000 });
      }
    },
    async checkLogin() {
      const tkn = "zetartant";
      const matches = document.cookie.match(new RegExp(
        // DON'T REMOVE BELOW COMMENT !  
        //eslint-disable-next-line
        "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      if (matches) {
        const deltat = Date.now() + 1.0e13 - Number(matches[1].substring(0, 14));
        if (deltat > 30 * 60 * 1000) {
          await this.CookieRemove();
          if (this.isLoggedIn) {
            this.toast.add({ severity: "warn", summary: "Warning", detail: "No activity for long time. Please re-login if needed.", life: 3000 });
          }
          if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
          this.isLoggedIn = false;
        } else if (deltat > 20 * 60 * 1000) {
          await this.makeRequestWith_DoubleToken();
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true;
        } else {
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true
        }
      } else {
        await this.CookieRemove();
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      }
    },
    async CookieRemove() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/logout_with_cookies`;
        const response = await axios.post(path);
        document.cookie = "zetartant=;max-age=0";
        console.log(response);
        if (this.isLoggedIn) {
          this.toast.add({ severity: "info", summary: "Info.", detail: "You were Logged-out", life: 3000 });
        }
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getUserJournal() {
      await this.checkLogin();
      if (this.isLoggedIn == true) {
        try {
          const path = `${process.env.VUE_APP_ROOT_API}/get_userjournal`;
          axios.defaults.withCredentials = true;
          const tkn = "zetartant";
          const matches = document.cookie.match(new RegExp(
            // DON'T REMOVE BELOW COMMENT !  
            //eslint-disable-next-line
            "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          const options1 = {
            headers: {
              'X-CSRF-TOKEN': matches[1].substring(14),
            }
          };
          const response = await axios.get(path, options1);
          this.userjournal = response.data;
          const vasya2 = response.headers.get(["Var-Antolog"]);
          if (vasya2) {
            const dtt = Date.now() + 1.0e13;
            const vasya3 = "zetartant=" + String(dtt) + vasya2;
            document.cookie = vasya3;
          }
          this.flagStatus1 = false;
          this.flagStatus2 = false;
          this.flagStatus3 = false;
          this.flagStatus4 = false;
          this.flagStatus5 = false;
          this.ord_paid = [];
          this.ord_sum_paid = [];
          this.ord_notpaid = [];
          this.ord_sum_notpaid = [];
          this.cart_sum = 0;
          for (let element1 of this.userjournal) {
            if (element1.status_journ == 1) {
              this.flagStatus1 = true;
              this.cart_sum = this.cart_sum + element1.product_price_journ;
            }
            if (element1.status_journ == 2) {
              this.flagStatus2 = true;
              let indexOrd2 = this.ord_notpaid.indexOf(element1.order_no_journ);
              if (indexOrd2 < 0) {
                this.ord_notpaid.push(element1.order_no_journ);
                this.ord_sum_notpaid.push({
                  "order": element1.order_no_journ,
                  "sum": element1.product_price_journ
                });
              }
              else {
                this.ord_sum_notpaid[indexOrd2].sum
                  = this.ord_sum_notpaid[indexOrd2].sum
                  + element1.product_price_journ;
              }
            }
            if (element1.status_journ == 3) {
              this.flagStatus3 = true;
              let indexOrd3 = this.ord_paid.indexOf(element1.order_no_journ);
              if (indexOrd3 < 0) {
                this.ord_paid.push(element1.order_no_journ);
                this.ord_sum_paid.push({
                  "order": element1.order_no_journ,
                  "sum": element1.product_price_journ
                });
              }
              else {
                this.ord_sum_paid[indexOrd3].sum
                  = this.ord_sum_paid[indexOrd3].sum
                  + element1.product_price_journ;
              }
            }
            if (element1.status_journ == 21 || element1.status_journ == 22) {
              this.flagStatus4 = true;
            }
            if (element1.status_journ == 4) {
              this.flagStatus5 = true;
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async ini() {
      await this.checkLogin();
      await this.getUserJournal(); //double checkLogin
    },
    async removeFromCart(p_id) {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/removeproduct_fromcart`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'prodid': p_id,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
      await this.getUserJournal();
    },
    async placeOrder() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/place_order`;
        axios.defaults.withCredentials = true;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.get(path, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
      await this.getUserJournal();
    },
    async cancelOrder(ord_id) {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/cancel_order`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'ordid': ord_id,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
      await this.getUserJournal();
    },
    choosePrepayment(x) {
      if (x == this.usepay_rec_id) { this.usepay_rec_id = 0; }
      else { this.usepay_rec_id = x; }
    },
    async allocatePrepayment(p_id) {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/allocate_prepayment`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'prodid': p_id, 'payid': this.usepay_rec_id,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
      await this.getUserJournal();
    },
    getInv(c, n) {
      //this.$router.push({ name: 'invoice', params: { curr: c, ordid: n } });
      this.$router.push({ name: 'invoice', query: { curr: c, ordid: n } });
    },
  },
  mounted() {
    this.ini();
  },
  watch: {
    isLoggedIn(newflag) {
      if (newflag) { this.ilt(); }
      else { this.ilf(); }
    }
  },
}
</script>

<style scoped>
.rem-class {
  color: red;
  padding-left: 5px;
  padding-right: 5px;
}

.rem-class:hover {
  cursor: pointer;
  background-color: yellowgreen;
}

.use-class {
  padding-left: 5px;
  padding-right: 5px;
}

.use-class:hover {
  cursor: pointer;
  color: yellow;
}

.standby {
  background-color: darkgrey;
  color: white;
}

.in-use {
  background-color: lightgreen;
  color: blue;
}

.pt {
  display: inline-block;
  vertical-align: middle;
}

table,
th,
td {
  border: 1px solid darkblue;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
}

.tc {
  margin-left: auto;
  margin-right: auto;
}

.inlblk {
  display: inline-block;
  vertical-align: middle;
}

.cbtn {
  background-color: #d1e4dd;
  border-radius: 15px;
  width: 100px;
  height: 40px;
}

.cbtn:hover {
  background-color: white;
  cursor: pointer;
}

.inl-2 {
  display: inline-block;
}

.btn-btc {
  background-image: url(../../assets/btc_0010_43_64.png);
  background-position-x: 10px;
  background-position-y: 2px;
  display: inline;
  background-repeat: no-repeat;
  background-size: 20px 30px;
}

.btn-eth {
  background-image: url(../../assets/eth_0010_43_64.png);
  background-position-x: 5px;
  background-position-y: 2px;
  display: inline;
  background-repeat: no-repeat;
  background-size: 20px 30px;
}
</style>
