<template>
  <div v-if="!isLoggedIn">
    <br><button @click="requireLogin" class="rleft" :class="{ btn_on: isRegistered, btn_off: !isRegistered }">Login
      Form</button>
    <button @click="requireRegistration" class="rright" :class="{ btn_on: !isRegistered, btn_off: isRegistered }">Register
      Form</button>
    <div v-if="isRegistered">
      <form @submit.prevent="AuthRequest">
        <br>Please use this form to Login if you have been registered before.
        <br>Otherwise please switch to Register Form and first apply for registration.
        <div class="container">
          <label for="email_1">Your Email:</label>
          <input type="textarea" id="email_1" v-model.trim="email1" pattern="(\w\.?)+@[\w\.-]+\.\w{2,}" />
          <br><br><label for="pwd_1">Password</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd1')">show / hide</span>
          <input type="password" id="pwd_1" ref="pwd1" v-model.trim="pass1" />
        </div>
        <div id="mywidget1"></div>
        <button type="submit" class="rsymm btn_act">Login me</button>
      </form>
      <br>
      <div class="rcontainer">
        <span class="showhide" @click="pwdForgot">Forgot password ? (open/close)</span>
      </div>
      <form v-show="forgotPassword" @submit.prevent="askforTempPass">
        If you forgot password please fill-in and submit below form. In short time
        <br>you will receive by email new temporary password. Please Login with it
        <br>and immediately change to your newly invented own permanent password
        <br>in section Login/Quit -> Your Profile -> Password Change
        <div class="container">
          <label for="email_2">Your Email:</label>
          <input type="textarea" id="email_2" v-model.trim="email2" pattern="(\w\.?)+@[\w\.-]+\.\w{2,}" />
        </div>
        <div id="mywidget2"></div>
        <button type="submit" class="rsymm btn_act">Temp Pass Request</button>
        <br><br>
      </form>
      <div class="rcontainer">
        <span class="showhide" @click="pwdChange">Want password change ? (open/close)</span>
      </div>
      <span v-if="wantNewPass">If you remember password but want to change it for some reason
        <br>then please Login as usual and go to appeared menu section
        <br>Login/Quit -> Your Profile. Change password button is placed in there.
      </span>
    </div>
    <div v-else>
      <form @submit.prevent="submitRegisterRequest">
        <br>Please use this form to Register your name, email and password in the system
        <br>if you have not registered them earlier. Otherwise please switch to Login Form.
        <br><br>Here asterisk sign (*) denotes required fields (name, email and password).
        <br>Other fields (nickname, second name and telephone) are optional and you may
        <br>leave them empty. But site wininfin.com may ask for optional information in case
        <br>of some future non-standard situations. You may register/ change/ delete optional
        <br>information later in Login/Quit -> Your Profile section which will appear in menu
        <br>after initial registration
        <div class="container">
          <label for="f_name">Name*</label>
          <input type="textarea" id="f_name" v-model.trim="firstname" />
          <span style="font-size: 12px; font-weight: bold;">Your first name</span>
          <br><br><label for="email_3">Email*</label>
          <input type="textarea" id="email_3" v-model.trim="email3" pattern="(\w\.?)+@[\w\.-]+\.\w{2,}" />
          <br><br><label for="pwd_2">Password*</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd2')">show / hide</span>
          <input type="password" id="pwd_2" ref="pwd2" v-model.trim="pass2" />
          <span style="font-size: 12px; font-weight: bold;">
            Min 8 max 20 symbols. At least 1 symbol from each set of [a-z], [A-Z], [0-9], [~!@#$%^&*?]
          </span>
          <br><br><label for="pwd_3">Repeat password*</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd3')">show / hide</span>
          <input type="password" id="pwd_3" ref="pwd3" v-model.trim="pass3" />
          <br><br><label for="n_name">Nickname</label>
          <input type="textarea" id="n_name" v-model.trim="nickname" />
          <span style="font-size: 12px; font-weight: bold;">
            If you want we may refer to your comments in some of our posts
          </span>
          <br><br><label for="s_name">Second name</label>
          <input type="textarea" id="s_name" v-model.trim="secondname" />
          <span style="font-size: 12px; font-weight: bold;">Your family name</span>
          <br><br><label for="n_tel">Telephone</label>
          <input type="textarea" id="n_tel" v-model.trim="telephone" />
          <span style="font-size: 12px; font-weight: bold;">Digits [0-9], symbols [ +#-/@&() ]</span>
          <br><br>
          <input type="checkbox" class="checkbox" v-model="check1">
          <span class="checklabel">I accept Terms of Use</span>
          <br><span class="readterms" @click="readPolicies">Read Terms of Use</span>
          <br><br>
        </div>
        <div id="mywidget3"></div>
        <button type="submit" class="rsymm btn_act">Register me</button>
      </form>
    </div>
  </div>
  <div v-else>
    <br><button @click="requireLogout" class="rleft" :class="{ btn_on: wantQuit, btn_off: !wantQuit }">Quit
      Options</button>
    <button @click="requireProfile" class="rright" :class="{ btn_on: !wantQuit, btn_off: wantQuit }">Your Profile</button>
    <div v-if="wantQuit">
      <br>
      <p style="max-width: 300px; margin: auto;">Sign-out but stay on wininfin.com as not registered (anonymous) user</p>
      <br><button @click="justQuit" class="rsymm btn_act">Quit</button>
      <br>
    </div>
    <div v-else>
      <br>
      <div class="container">
        <span> &emsp; &emsp; &emsp; Your Credentials</span>
        <br>Name: {{ c_name }}
        <br>Email: {{ c_email }}
        <br>Nickname: {{ c_nick }}
        <br>Second name: {{ c_sname }}
        <br>Telephone: {{ c_tel }}
      </div>
      <div class="rcontainer">
        <span class="showhide" @click="credChange">Edit Credentials (open/close)</span>
      </div>
      <form v-if="wantNewCred" @submit.prevent="askforNewCred">
        You may change/add/delete you credentials here.
        <br>Just re-type/type/clean them respectively. Notes: you
        <br>may not change your email. To change password please
        <br>open next form "Change Password".
        <div class="container">
          <label for="cf_name">Name</label>
          <input type="textarea" id="cf_name" v-model.trim="cfirstname" />
          <span style="font-size: 12px; font-weight: bold;">Your first name</span>
          <br><br><label for="cn_name">Nickname</label>
          <input type="textarea" id="cn_name" v-model.trim="cnickname" />
          <span style="font-size: 12px; font-weight: bold;">
            If you want we may refer to your comments in some of our posts
          </span>
          <br><br><label for="cs_name">Second name</label>
          <input type="textarea" id="cs_name" v-model.trim="csecondname" />
          <span style="font-size: 12px; font-weight: bold;">Your family name</span>
          <br><br><label for="cn_tel">Telephone</label>
          <input type="textarea" id="cn_tel" v-model.trim="ctelephone" />
          <span style="font-size: 12px; font-weight: bold;">Digits [0-9], symbols [ +#-/@&() ]</span>
          <br><br>Enter password to verify changes
          <label for="pwd_4">Password*</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd4')">show / hide</span>
          <input type="password" id="pwd_4" ref="pwd4" v-model.trim="pass4" />
        </div>
        <button type="submit" class="rsymm btn_act">Change Credentials</button>
        <br><br>
      </form>
      <div class="rcontainer">
        <span class="showhide" @click="pwdChangeForm">Password Change (open/close)</span>
      </div>
      <form v-if="wantPassChange" @submit.prevent="askforPwdChange">
        <div class="container">
          <label for="pwd_5">Old Password</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd5')">show / hide</span>
          <input type="password" id="pwd_5" ref="pwd5" v-model.trim="pass5" />
          <span style="font-size: 12px; font-weight: bold;">Enter password or temporary password</span>
          <br><br><label for="pwd_6">New Password</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd6')">show / hide</span>
          <input type="password" id="pwd_6" ref="pwd6" v-model.trim="pass6" />
          <br><br><label for="pwd_7">Repeat New Password</label>
          <span class="showhide" @click="pwdShowHide($event, 'pwd7')">show / hide</span>
          <input type="password" id="pwd_7" ref="pwd7" v-model.trim="pass7" />
        </div>
        <button type="submit" class="rsymm btn_act">Request Pass Change</button>
        <br><br>
      </form>
    </div>
  </div>
  <Toast />
</template>

<script>

import axios from 'axios';
import { useToast } from '../../../node_modules/primevue/usetoast';
import { ref, inject } from 'vue';

export default {
  setup() {
    const isLoggedGlob = inject("isLoggedGlob", ref(false));
    return {
      isLoggedGlob,
      ilt() {
        isLoggedGlob.value = true;
      },
      ilf() {
        isLoggedGlob.value = false;
      },
    };
  },
  data() {
    return {
      token: "token vasya",
      widgetID: "",
      toast: useToast(),
      isLoggedIn: false,
      isRegistered: true,
      forgotPassword: false,
      wantNewPass: false,
      acceptTerms: false,
      wantQuit: true,
      wantNewCred: false,
      wantPassChange: false,
      typePassword: "password",
      showhide: "Show",
      firstname: "", secondname: "", nickname: "", telephone: "",
      c_name: "", c_email: "", c_nick: "", c_sname: "", c_tel: "",
      cfirstname: "", csecondname: "", cnickname: "", ctelephone: "",
      email1: "", email2: "", email3: "", email4: "",
      pass1: "", pass2: "", pass3: "", pass4: "", pass5: "", pass6: "", pass7: "",
      check1: false,
      xx: "initial cookie name is empty",
      yy: "JWT from axios response - initially empty",
      zz: "JWT from storage - initially empty",
      aa: "initially empty",
      bb: "initially empty",
      cc: 0,
    };
  },
  methods: {
    async AuthRequest() {
      try {
        let vasya = await this.renderWidget('#mywidget1');
        this.token = vasya;
        await this.removeWidget();
      } catch (error) {
        console.log(error);
      }

      try {
        const path = `${process.env.VUE_APP_ROOT_API}/login_with_cookies`;
        axios.defaults.withCredentials = true;
        const data0 = {
          'usermail': this.email1, 'userpass': this.pass1, "wtoken": this.token,
        };
        const response = await axios.post(path, data0);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        const dtt = Date.now() + 1.0e13;
        const vasya3 = "zetartant=" + String(dtt) + vasya2;
        document.cookie = vasya3;
        if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
        this.isLoggedIn = true;
        await this.$router.push("/catalog");
        this.toast.add({ severity: "success", summary: "Success !", detail: "You are logged in now", life: 3000 });
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "error", summary: "Login Error !", detail: "Please check email and password", life: 3000 });
      }
    },
    async justQuit() {
      await this.CookieRemove();
      this.toast.add({ severity: "info", summary: "Info.", detail: "You were Logged-out", life: 3000 });
      await this.$router.push("/catalog");
    },
    async CookieRemove() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/logout_with_cookies`;
        //const response = await axios.post(path);
        await axios.post(path);
        document.cookie = "zetartant=;max-age=0";
        //console.log(response);
        if (this.isLoggedIn) {
          this.toast.add({ severity: "info", summary: "Info.", detail: "You were Logged-out", life: 3000 });
        }
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      } catch (error) {
        console.error(error);
      }
    },
    async makeRequestWith_DoubleToken() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/ping`;
        //        axios.defaults.withCredentials = true;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.get(path, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      }
    },
    requireLogin() {
      this.isRegistered = true;
    },
    requireRegistration() {
      this.isRegistered = false;
    },
    async submitRegisterRequest() {
      if (!this.check1) {
        this.toast.add({ severity: "error", summary: "Error.", detail: "Please accept Terms of Use", life: 3000 });
        return
      }
      if (this.pass2 != this.pass3) {
        this.toast.add({ severity: "error", summary: "Error - check passwords !", detail: "Two passwords should match !", life: 3000 });
        return
      }

      try {
        let vasya = await this.renderWidget('#mywidget3');
        this.token = vasya;
        await this.removeWidget();
      } catch (error) {
        console.log(error);
      }

      try {
        const path = `${process.env.VUE_APP_ROOT_API}/register_with_cookies`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'usermail': this.email3, 'userpass': this.pass2, "wtoken": this.token,
        };
        if (this.firstname) { data1['firstname'] = this.firstname }
        if (this.nickname) { data1['nickname'] = this.nickname }
        if (this.secondname) { data1['secondname'] = this.secondname }
        if (this.telephone) { data1['telephone'] = this.telephone }
        const response = await axios.post(path, data1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        const dtt = Date.now() + 1.0e13;
        const vasya3 = "zetartant=" + String(dtt) + vasya2;
        document.cookie = vasya3;
        if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
        this.isLoggedIn = true;
        await this.$router.push("/catalog");
        this.toast.add({ severity: "success", summary: "Success !", detail: "You were registered and logged in now", life: 3000 });
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "error", summary: "Error - no registration !", detail: "Please check form fields", life: 3000 });
      }
    },
    pwdForgot() {
      this.forgotPassword = !this.forgotPassword;
    },
    pwdChange() {
      this.wantNewPass = !this.wantNewPass;
    },
    requireLogout() {
      this.wantQuit = true;
    },
    requireProfile() {
      this.wantQuit = false;
      if (this.c_name == "") { this.getUserProfile() }
    },
    pwdShowHide(event, x) {
      if (this.$refs[x].type == "password") {
        this.$refs[x].type = "textarea";
      } else {
        this.$refs[x].type = "password";
      }
    },
    async askforTempPass() {
      try {
        let vasya = await this.renderWidget('#mywidget2');
        this.token = vasya;
        await this.removeWidget();
      } catch (error) {
        console.log(error);
      }

      try {
        const path = `${process.env.VUE_APP_ROOT_API}/get_tmp_password`;
        axios.defaults.withCredentials = true;
        const data0 = {
          'usermail': this.email2, "wtoken": this.token,
        };
        await axios.post(path, data0);
        this.toast.add({ severity: "info", summary: "Info", detail: "Request accepted. Check your email.", life: 3000 });
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "error", summary: "Request Error !", detail: "Wrong email", life: 3000 });
      }
    },
    async getUserProfile() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/get_user_profile`;
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.get(path, options1);
        const rdata = response.data;
        this.c_name = rdata.firstname;
        this.c_email = rdata.usermail;
        this.c_nick = rdata.nickname;
        this.c_sname = rdata.secondname;
        this.c_tel = rdata.telephone;
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async credChange() {
      this.wantNewCred = !this.wantNewCred;
      if (this.wantNewCred == true) {
        if (this.c_name == "") { await this.getUserProfile() }
        this.cfirstname = this.c_name;
        this.csecondname = this.c_sname;
        this.cnickname = this.c_nick;
        this.ctelephone = this.c_tel;
      }
    },
    pwdChangeForm() {
      this.wantPassChange = !this.wantPassChange;
    },
    async askforNewCred() {
      if (this.cfirstname == "") {
        this.toast.add({ severity: "error", summary: "Error !", detail: "[Name] is required field !", life: 3000 });
        return
      }
      let changeflag = 0;
      if (this.cfirstname != this.c_name) { changeflag = 1 }
      if (this.cnickname != this.c_nick) { changeflag = 1 }
      if (this.csecondname != this.c_sname) { changeflag = 1 }
      if (this.ctelephone != this.c_tel) { changeflag = 1 }
      if (changeflag == 0) {
        this.toast.add({ severity: "info", summary: "Info.", detail: "You did not change credentials", life: 3000 });
        return
      }
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/update_credentials`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'usermail': this.c_email, 'userpass': this.pass4,
        };
        if (this.cfirstname) { data1['firstname'] = this.cfirstname }
        if (this.cnickname) { data1['nickname'] = this.cnickname }
        if (this.csecondname) { data1['secondname'] = this.csecondname }
        if (this.ctelephone) { data1['telephone'] = this.ctelephone }
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        const dtt = Date.now() + 1.0e13;
        const vasya3 = "zetartant=" + String(dtt) + vasya2;
        document.cookie = vasya3;
        if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
        this.isLoggedIn = true;
        await this.getUserProfile();
        this.toast.add({ severity: "success", summary: "Success !", detail: "Credentials changed", life: 3000 });
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "error", summary: "Error - no changes !", detail: "Please check form fields", life: 3000 });
      }
    },
    async askforPwdChange() {
      if (this.pass6 != this.pass7) {
        this.toast.add({ severity: "error", summary: "Error - check passwords !", detail: "Two passwords should match !", life: 3000 });
        return
      }
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/password_change`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'userpassold': this.pass5, 'userpassnew': this.pass7,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        const vasya2 = response.headers.get(["Var-Antolog"]);
        const dtt = Date.now() + 1.0e13;
        const vasya3 = "zetartant=" + String(dtt) + vasya2;
        document.cookie = vasya3;
        this.toast.add({ severity: "success", summary: "Success !", detail: "Password changed", life: 3000 });
      } catch (error) {
        console.error(error);
        this.toast.add({ severity: "error", summary: "Error - no password change !", detail: "Please check form fields", life: 3000 });
      }
    },
    async checkLogin() {
      const tkn = "zetartant";
      const matches = document.cookie.match(new RegExp(
        // DON'T REMOVE BELOW COMMENT !  
        //eslint-disable-next-line
        "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      if (matches) {
        const deltat = Date.now() + 1.0e13 - Number(matches[1].substring(0, 14));
        if (deltat > 30 * 60 * 1000) {
          await this.CookieRemove();
          if (this.isLoggedIn) {
            this.toast.add({ severity: "warn", summary: "Warning", detail: "No activity for long time. Please re-login if needed.", life: 3000 });
          }
          if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
          this.isLoggedIn = false;
        } else if (deltat > 20 * 60 * 1000) {
          await this.makeRequestWith_DoubleToken();
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true;
        } else {
          if (this.isLoggedIn) { this.ilt(); } // call ilt() anyway even if this.isLoggedIn is true
          this.isLoggedIn = true
        }
      } else {
        await this.CookieRemove();
        if (!this.isLoggedIn) { this.ilf(); } // call ilf() anyway even if this.isLoggedIn is false
        this.isLoggedIn = false;
      }
    },
    readPolicies() {
      this.$router.push("/policies");
    },
    async removeWidget() {
      window.turnstile.remove(this.widgetID);
    },
    async renderWidget(widid) {
      let prms = new Promise((res, rej) => {
        let wid = window.turnstile.render(widid, {
          sitekey: '0x4AAAAAAAJl66QXDxovjQwU',
          callback: function (token1) {
            console.log(`Challenge Success 1 ${token1}`);
            res(token1);
          },
          'error-callback': function (err) {
            // for example if wrong sitekey
            console.log('Turnstile error: ', err);
            rej(new Error('error1'));
          }
        });
        this.widgetID = wid;
      });
      return prms;
    },
  },
  async mounted() {

    await this.checkLogin();

    function asyncLoadTurnstileScript() {
      const src0 = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      const turnstileLoadFunction = "";
      const src = `${src0}?render=explicit${turnstileLoadFunction}`;
      const promise = new Promise((resolve, reject) => {

        // Check existance of turnstile script tag
        const prev = document.querySelector(`script[src="${src}"`)
        if (prev) {
          console.info('Turnstile script tag already exists')
          if (prev.dataset.state === 'loaded') return resolve();
          else prev.remove()
        }

        // Create script element
        const el = document.createElement('script')
        el.src = src
        el.defer = true
        el.async = true
        // Add event handlers onload and onerror
        el.onload = () => {
          el.dataset.state = 'loaded'
          resolve()
        }
        el.onerror = () => reject(new Error('Failed to load turnstile script'))

        // Append script to html head section
        document.head.appendChild(el)
      })

      // function returns promise and can be used with 'await' keyword
      return promise
    }

    await asyncLoadTurnstileScript()
  },
  beforeUnmount() {
    this.removeWidget();
  },
  watch: {
    isLoggedIn(newflag) {
      if (newflag) { this.ilt(); }
      else { this.ilf(); }
    }
  },
}
</script>

<style scoped>
button {
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.btn_on {
  border: 3px solid;
  color: white;
  background-color: blue;
  border-color: blue;
}

.btn_off {
  border: 3px solid;
  color: rgb(0, 140, 252);
  background-color: #d1e4dd;
  border-color: rgb(0, 140, 252);
}

.btn_off:hover {
  border-color: blue;
}

.btn_act {
  border: 3px solid;
  color: white;
  background-color: blue;
  border-color: blue;
}

.btn_act:hover {
  border-color: white;
}

.rleft {
  border-radius: 20px 0 0 20px;
}

.rright {
  border-radius: 0 20px 20px 0;
}

.rsymm {
  border-radius: 20px;
}

input {
  width: 100%;
  height: 40px;
  padding: 0.75rem 1.5rem;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10 px;
}

.showhide {
  color: blue;
  font-style: italic;
  text-align: right;
}

.showhide:hover {
  cursor: pointer;
}

.container {
  max-width: 300px;
  padding: 15px;
  text-align: left;
  margin: auto;
}

.rcontainer {
  max-width: 300px;
  padding: 5px;
  text-align: right;
  margin: auto;
}

label {
  width: 70%;
  float: left;
}

.checkbox {
  display: inline-block;
  vertical-align: middle;
  max-width: 25px;
  max-height: 25px;
}

.checklabel {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 5px;
}

.readterms {
  padding-left: 45px;
  color: blue;
  font-style: italic;
}

.readterms:hover {
  cursor: pointer;
}
</style>