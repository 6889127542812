<template>
  <div>
    <span style="font-size: 120%; font-weight: bold;">Invoice</span>
    <br><span class="container400">Time to expiration: <span style="color:blueviolet; font-weight: bold;">{{ timerstring
    }}</span></span>
    <p style="size:120px" v-html="myqrc"></p>
    <a v-if="curr == 'ETH'" class="pw">Pay with <img src="../../assets/eth_0010_43_64.png" class="pw"
        style="width:26px; height:39px;">
      Ethereum</a>
    <a v-if="curr == 'BTC'" class="pw">Pay with <img src="../../assets/btc_0010_43_64.png" class="pw"
        style="width:26px; height:39px;">
      Bitcoin</a>
    <br><br>Send exactly &nbsp;
    <span style="font-size:100%; background-color: white;">&nbsp; {{ invoi.amnt_inv }}&nbsp; </span>
    &nbsp; {{ curr }}
    <button class="cpb" @click="copyToClipboard('amnt')">&#9998;</button> -copy
    <br><br>to this {{ cxxx }} address: &ensp; &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;
    <button class="cpb" @click="copyToClipboard('address')">&#9998;</button> -copy
    <br><span style="background-color: white; position: relative; top:5px">&nbsp; {{ invoi.coin_addr }} &nbsp;</span>
    <!--    <button class="cpb" @click="copyToClipboard('address')">&#9998;</button> -copy -->
    <div class="container400">
      <br>&emsp; Scan the QR code or copy and paste
      above payment details into your wallet
      <br><br>Please make payment before time to expiration
      is over. Afterwards the rate will expire and
      you will have to create new invoice
      <br><br>Send only {{ cxx }} ({{ curr }}) to this
      address. Sending any other coins may
      result in permanent loss.
      <div class="dcont">
        <br>&emsp; detailed invoice information:
        <br>Invoice number: <span class="dinv">{{ invoi.inv_no }}</span>
        <br>Date: <span class="dinv">{{ new Date().toISOString().substring(0, 10) }}</span> (UTC zone)
        <br>Order number: <span class="dinv">{{ ordid }}</span>
        <br>From: <span class="dinv">https://www.wininfin.com</span>
        <br>Invoice currency: <span class="dinv">{{ curr }} ({{ cxxx }})</span>
        <br>Invoice amount: <span class="dinv">{{ invoi.amnt_inv }} {{ curr }}</span>
        <br>Payment address:
        <br>
        <div class="adcont"><span class="dinv">{{ invoi.coin_addr }}</span></div>
        <br>Order/invoice amount in USD: <span class="dinv">{{ invoi.usd_inv }}</span> &nbsp;$
        <br>Invoice volume bonus: <span class="dinv">{{ invoi.ivb_usd }}</span> &nbsp;$
        <br><span style="font-size:90%">(see terms in the cart page)</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { qrcode } from './QRCode.js'

export default {
  data() {
    return {
      curr: "",
      cx: "",
      cxx: "",
      cxxx: "",
      cxxx1: "",
      amnt_inv: 0,
      rate: 1,
      ordid: 0,
      camnt: 0,
      invoi: [],
      deltatime: 0,
      timerstring: "",
      timerID: "",
      qrc: "j",
      myqrc: "k",
    };
  },
  methods: {
    async readParams() {
      //this.curr = this.$route.params.curr;
      //this.ordid = this.$route.params.ordid;
      this.curr = this.$route.query.curr;
      this.ordid = this.$route.query.ordid;
      if (this.curr == "BTC") {
        this.cxx = "Bitcoin"
        this.cxxx = "Bitcoin";
        this.cxxx1 = "bitcoin";
      }
      if (this.curr == "ETH") {
        this.cxx = "Ether"
        this.cxxx = "Ethereum";
        this.cxxx1 = "ethereum";
      }
      await this.getInvoice();
    },
    async getInvoice() {
      try {
        const path = `${process.env.VUE_APP_ROOT_API}/get_invoice`;
        axios.defaults.withCredentials = true;
        const data1 = {
          'cur': this.curr, 'ordid': this.ordid,
        };
        const tkn = "zetartant";
        const matches = document.cookie.match(new RegExp(
          // DON'T REMOVE BELOW COMMENT !  
          //eslint-disable-next-line
          "(?:^|; )" + tkn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        const options1 = {
          headers: {
            'X-CSRF-TOKEN': matches[1].substring(14),
          }
        };
        const response = await axios.post(path, data1, options1);
        this.invoi = response.data;
        this.deltatime = response.data['deltatime']
        this.timerID = setInterval(this.runMyTimer, 1000);
        this.generateMyQr();
        const vasya2 = response.headers.get(["Var-Antolog"]);
        if (vasya2) {
          const dtt = Date.now() + 1.0e13;
          const vasya3 = "zetartant=" + String(dtt) + vasya2;
          document.cookie = vasya3;
        }
      } catch (error) {
        console.error(error);
      }
    },
    runMyTimer() {
      this.deltatime--;
      if (this.deltatime <= 0) {
        //        document.getElementById("expired").innerHTML = "Time has gone !";
        clearInterval(this.timerID);
      }

      let ccsec = this.deltatime;
      let hh = parseInt(ccsec / 3600);
      ccsec = ccsec - hh * 3600;
      let mm = parseInt(ccsec / 60);
      ccsec = ccsec - mm * 60;
      let ss = parseInt(ccsec);
      let hhstr = "";
      let mmstr = "";
      let ssstr = "";

      if (hh > 9) { hhstr = hh.toString() + ":"; }
      else if (hh > 0) { hhstr = "0" + hh.toString() + ":"; }
      else { hhstr = "00:"; }

      if (mm > 9) { mmstr = mm.toString() + ":"; }
      else if (mm > 0) { mmstr = "0" + mm.toString() + ":"; }
      else { mmstr = "00:"; }

      if (ss > 9) { ssstr = ss.toString(); }
      else if (ss > 0) { ssstr = "0" + ss.toString(); }
      else { ssstr = "00"; }

      this.timerstring = hhstr + mmstr + ssstr;
      //      document.getElementById("mytimer").innerHTML = timerstring;
    },
    generateMyQr() {
      var typeNumber = 4;
      var errorCorrectionLevel = 'L';
      var qr = qrcode(typeNumber, errorCorrectionLevel);
      qr.addData(this.cxxx1 + ':' + this.invoi.coin_addr + '?amount=' + this.invoi.amnt_inv);
      qr.make();
      // Below 4 identifies size of QR code
      this.myqrc = qr.createImgTag(4);
      //console.log(this.myqrc);
    },
    draw_qrcode(text, typeNumber, errorCorrectionLevel) {
      document.write(this.create_qrcode(text, typeNumber, errorCorrectionLevel));
    },
    create_qrcode(text, typeNumber, errorCorrectionLevel, mode) {
      // !!! last parameter mb was deleted from previous function parameters
      //this.qrcode.stringToBytes = this.qrcode.stringToBytesFuncs[mb];
      var qr = qrcode(typeNumber || 4, errorCorrectionLevel || 'M');
      qr.addData(text, mode);
      qr.make();
      return qr.createImgTag();
    },
    update_qrcode() {
      // https://kazuhikoarase.github.io/qrcode-generator/js/demo/
      var text = "Vasya Pupkin"
      //	replace(/^[\s\u3000]+|[\s\u3000]+$/g, '');
      // TypeNumber: t = 1 - 40 or AutoDetect (4 ?)
      var t = 4;
      // ErrorCorrectionLevel: e = "L" (L7%), "M" (M15%), "Q" (Q25%), "H" (H30%)
      var e = "M";
      // mode: m = "Numeric" , "Alphanumeric" ,  "Byte" , "Kanji" 
      var m = "Byte"
      // Multibyte: mb = "default", "SJIS", "UTF-8"
      var mb = "UTF-8";
      // document.getElementById('qr').innerHTML =
      this.qrc = this.create_qrcode(text, t, e, m, mb);
      //console.log(this.qrc);
    },
    async copyToClipboard(x = "") {
      try {
        if (x == 'amnt') {
          await navigator.clipboard.writeText(this.invoi.amnt_inv);
        }
        if (x == 'address') {
          await navigator.clipboard.writeText(this.invoi.coin_addr);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    //console.log("deltatime at mount1 = ", this.deltatime);
    //console.log("mounted curr=", this.$route.query.curr);
    this.readParams();
    //console.log("deltatime at mount2 = ", this.deltatime);
  },
}
</script>

<style scoped>
.rleft {
  border-radius: 20px 0 0 20px;
}

.rright {
  border-radius: 0 20px 20px 0;
}

.rsymm {
  border-radius: 20px;
}

.container400 {
  max-width: 400px;
  padding: 15px;
  text-align: left;
  margin: auto;
}

.rcontainer {
  max-width: 300px;
  padding: 5px;
  text-align: right;
  margin: auto;
}

.dcont {
  max-width: 300px;
  padding: 5px;
  text-align: left;
  margin: auto;
}

.adcont {
  max-width: 190px;
  padding: 5px;
  text-align: left;
  margin: auto;
  word-wrap: break-word;
  display: inline-block;
}

.pw {
  display: inline-block;
  vertical-align: middle;
}

.cpb {
  font-size: 120%;
  border: none;
  background-color: #d1e4dd;
}

@media (hover:hover) {
  .cpb:hover {
    cursor: pointer;
    color: white;
    background-color: green;
  }
}

@media (hover:none) {
  .cpb:active {
    color: white;
    background-color: green;
  }
}

.dinv {
  font-weight: bold;
}
</style>
