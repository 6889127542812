import { createApp } from 'vue'

import router from './router.js'
import App from './App.vue'
// import VueMq from 'vue-mq'

import PrimeVue from '../node_modules/primevue/config'
import ToastService from '../node_modules/primevue/toastservice'

import Button from '../node_modules/primevue/button'
import InputText from '../node_modules/primevue/inputtext'
import Toast from '../node_modules/primevue/toast'
import Menubar from '../node_modules/primevue/menubar'
import TabView from '../node_modules/primevue/tabview'
import TabPanel from '../node_modules/primevue/tabpanel'
import Paginator from '../node_modules/primevue/paginator'

import '../node_modules/primevue/resources/themes/lara-light-indigo/theme.css'   //theme
import '../node_modules/primevue/resources/primevue.min.css'   //core css
import '../node_modules/primeicons/primeicons.css'   //icons
import '../node_modules/primeflex/primeflex.css'   //primeflex

const app = createApp(App)

app.use(router)
app.use(PrimeVue)
app.use(ToastService)

app.component('Button', Button)
app.component('InputText', InputText)
app.component('Toast', Toast)
app.component('Menubar', Menubar)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Paginator', Paginator)

app.mount('#app')
